import {useEffect} from 'react';
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from 'chart.js';
import { Doughnut } from 'react-chartjs-2';
import styled from 'styled-components'

ChartJS.register(ArcElement, Tooltip, Legend);



export const Chart = styled(Doughnut)`
width: 100%!important;
height: unset!important;

canvas{
    width: 100%!important;
    height: unset!important;
    
}

`

function PieChart({data,labels,unit}) {



     let dataObj = {
        labels: labels,
        datasets: [
          {
            // label: '# of Votes',
            data: data,
            backgroundColor: [
              'rgba(255, 99, 132, 0.2)',
              'rgba(54, 162, 235, 0.2)',
              'rgba(255, 206, 86, 0.2)',
              'rgba(75, 192, 192, 0.2)',
              'rgba(153, 102, 255, 0.2)',
              'rgba(255, 159, 64, 0.2)',
            ],
            borderColor: [
              'rgba(255, 99, 132, 1)',
              'rgba(54, 162, 235, 1)',
              'rgba(255, 206, 86, 1)',
              'rgba(75, 192, 192, 1)',
              'rgba(153, 102, 255, 1)',
              'rgba(255, 159, 64, 1)',
            ],
            borderWidth: 1,
          },
        ],
      };

  return (
  <Chart data={dataObj} updateMode="resize"/>
  )
}

export default PieChart




